.bg-primary {
  background-color: $white-color !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-light {
  background-color: var(--light) !important;
}

.bg-grey {
  background-color: $lightgrey !important;
}

.bg-warning {
  background: rgba(255, 201, 0, 0.49) !important;
}

.bg-danger {
  background: rgba(255, 190, 190, 0.49) !important;
}

.bg-red {
  background-color: var(--primary) !important;
}

.bg-info {
  background-color: var(--info) !important;
}

//inverse

.bg-primary-inverse {
  background-color: $white-inverse-color !important;
}

.bg-info-inverse {
  background-color: $info-inverse-color !important;
}

.bg-red-inverse {
  background-color: $primary-inverse-color !important;
}
